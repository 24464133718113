//演示环境
let env = 0; //0测试环境  1线上环境
let requestUrl, imgUrl, authHost, updateUrl
if (env == 0) {
	requestUrl = 'http://www.allnfts.cn';
	// requestUrl = 'http://jvqp9e.natappfree.cc'
	imgUrl = 'http://81.69.172.13:18080/ipfs/';
	authHost = 'http://test.wx.allnfts.cn';
	updateUrl = "http://app.metacbc.cn/api/update/devversion"
} else {
	requestUrl = 'https://nft.allnfts.cn';
	imgUrl = 'https://ipfs.allnfts.cn/ipfs/';
	authHost = 'https://wx.allnfts.cn';
	updateUrl = "http://app.metacbc.cn/api/update/version"
}
//线上环境

//固定参数
const appId = 'wx23406d34d48c253c';
const host = authHost + '/';
const authUrl = 'https://auth.allnfts.cn'
export default {
	env,
	appId,
	requestUrl,
	imgUrl,
	authHost,
	host,
	authUrl,
	updateUrl
}
